<template>
  <section id="contact-form" class="section contact-form" :style="sectionBackground">
    <div class="contact-form__image" :style="sectionImage"></div>
    <div class="container">
      <div class="contact-form__content">
        <h3 class="contact-form__title">{{ section.title }} <span>{{ section.subtitle }}</span></h3>

        <div v-if="formStatus === 'success' && formMessage" class="contact-form__message contact-form__success">
          <p>{{ formMessage }}</p>
        </div>

        <div v-if="formStatus === 'error' && formMessage" class="contact-form__message contact-form__error">
          <p>{{ formMessage }}</p>
        </div>

        <div >
          <form @submit.prevent="onSubmit" class="contact-form__form" novalidate>
            <InputField
                v-for="(field, index) in form.fields"
                :key="index"
                v-model="formData[field.name]"
                :id="field.name"
                :label="field.label"
                :type="field.type"
                :pattern="field.pattern"
                :required="field.required"
                :error="fieldErrors[field.name]"
                @input="event => handleInput(event, field.name)"
              />
            <p class="contact-form__disclaimer">{{ section.disclaimer }}</p>
            <button class="contact-form__button button button--tertiary" type="submit" :disabled="buttonDisabled">Оставить заявку</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
  import InputField from '@/components/InputField.vue';
  
  export default {
    components: {
      InputField,
    },

    data() {
      return {
        section: {
          title: 'Закажите услуги для своего бизнеса у нас и получите',
          subtitle: 'качественный сервис за приемлемые деньги',
          disclaimer: 'Нажимая на кнопку, вы соглашаетесь с условиями обработки персональных данных и политикой конфиденциальности',
          background: 'linear-gradient(135deg, #00C000, #ffff00)',
        },

        formStatus: '',
        formMessage: '',

        buttonDisabled: false,
        fieldErrors: {},

        formData: {
          name: '',
          phone: '',
          email: ''
        },

        form: {
          messages: {
            success: 'Спасибо за заявку! Мы свяжемся с вами в ближайшее время.',
            error: 'Что-то пошло не так. Попробуйте еще раз.'
          },
          errors: {
            empty: 'Поле обязательно для заполнения',
            invalid: 'Поле заполнено некорректно'
          },
          fields: [
            {
              name: 'name',
              label: 'Ваше имя',
              type: 'text',
              required: true,
            },
            {
              name: 'phone',
              label: 'Ваш телефон',
              type: 'tel',
              required: true,
              pattern: '^\\+7\\s\\d{3}\\s\\d{3}\\s\\d{4}$',
            },
            {
              name: 'email',
              label: 'Ваш email',
              type: 'email',
              required: true,
              pattern: '[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,25}$',
            },
          ]
        },
      };
    },

    methods: {

      onSubmit() {
        this.formMessage = '';
        this.formStatus = '';

        if(this.validateForm()){
          this.buttonDisabled = true;

          let apiUrl = '/api/';

          fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(this.formData)
          })
          .then(response => response.json())
          .then(data => {
            this.buttonDisabled = false;

            if (data.success || data.id) {
              this.resetForm();
              this.formStatus = 'success';
              this.formMessage = this.form.messages.success;
            } 
            
            else {
              this.formStatus = 'error';
              this.formMessage = this.form.messages.error;
            }

          })
          .catch(error => {
            this.buttonDisabled = false;
            this.formStatus = 'error';
            this.formMessage = this.form.messages.error;
          });
        } 
        
        else {
          this.formStatus = 'error';
        }
      },

      validateForm() {
        let isValid = true;
        this.fieldErrors = {};

        this.form.fields.forEach((field) => {
          const value = this.formData[field.name];

          if (field.required && !value) {
            this.fieldErrors[field.name] = this.form.errors.empty;
            isValid = false;
          } 
          
          else if(field.pattern && !new RegExp(field.pattern).test(value)) {
            this.fieldErrors[field.name] = this.form.errors.invalid;
            isValid = false;
          }
        });

        return isValid;
      },

      handleInput(event, fieldName) {
        const value = event.target.value;

        if (fieldName === 'phone') {
          this.formatPhoneNumber(fieldName, value);
        } 
        
        else {
          this.formData[fieldName] = value;
        }
      },

      formatPhoneNumber(fieldName, value) {
        value = value.replace(/\D/g, ''); // Remove all non-digit characters

        if (value.length > 11) {
          value = value.slice(0, 11);
        }

        let formatted = '+7 ';

        // If the value already starts with +7, remove it
        if (value.startsWith('7')) {
          value = value.substring(1);
        }

        // Add spaces after the first 3, 6, and 9 digits
        for (let i = 0; i < value.length; i++) {
          if (i === 3 || i === 6) {
            formatted += ' ';
          }
          formatted += value[i];
        }

        this.formData[fieldName] = formatted;
      },

      resetForm() {
        this.formData = {
          name: '',
          phone: '',
          email: ''
        };
      }
    },

    computed: {
      sectionBackground() {
        return {
          backgroundImage: this.section.background,
        };
      },
      sectionImage() {
        let image = require('@/assets/images/contact_form.jpg');
        return {
          backgroundImage: `url(${image})`,
        };
      },
    }
  };
</script>
  
<style scoped lang="scss">

.contact-form {
  position: relative;

  .container {
    display: flex;
    justify-content: flex-end;
  }

  &__image {
    display: none;

    @include media-min(m){
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(50% - 2rem);
      background-size: auto;
      background-position: center left;
    }
  }

  &__content {
    width: 100%;

    @include media-min(m){
      width: 50%;
    }
  }

  &__title {
    font-size: var(--font-size-h4);
    line-height: 1.2;
    color: var(--color-supplement-dark);

    span {
      margin-top: var(--padding-base);
      display: block;
      font-size: var(--font-size-h3);
      color: var(--color-white);
      line-height: 1;
    }
  }

  &__form {
    margin: 2rem 0;
  }

  &__disclaimer {
    font-size: 0.9rem;
    line-height: 1.2;
    color: var(--color-supplement-dark-opacity)

  }

  &__button {
    margin-top: 2rem;
  }

  &__message {
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
    font-size: 0.9rem;
    line-height: 1.2;
    color: var(--color-white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    &.contact-form__success {
      background-color: var(--color-success);
    }

    &.contact-form__error {
      background-color: var(--color-error);
    }
  }
}

</style>
  