<template>
  <section class="section section--tertiary principles">
    <div class="container">
      <h2 class="section__title principles__title">{{ sectionTitle }}</h2>
      <div class="principles__content">
        <PrincipleItem v-for="(principle, index) in principles" :key="index" :principle="principle.text" :number="index + 1" />
      </div>
    </div>
  </section>
</template>
  
<script>
  import PrincipleItem from '@/components/PrincipleItem.vue';
  
  export default {
    components: {
      PrincipleItem
    },
    data() {
      return {
        sectionTitle: 'Наши основные принципы',
        principles: [
          {
            text: 'Предоставлять высококачественные услуги, которые удовлетворяют и, более того, предвосхищают желание и потребности Партнера.'
          },
          {
            text: 'Быть ответственным перед Персоналом и Партнерами, формировать тесные отношения, основанные в первую очередь на честности и доверии.'
          },
          {
            text: 'Создавать такие условия работы Персонала, чтобы люди сами стремились к максимальной отдаче, на которую они способны.'
          },
          {
            text: 'Выполнять все обязательства перед Партнером и Персоналом точно и в срок, учитывая и уважая желания каждого'
          }
        ]
      };
    }
  }
</script>
  
<style scoped lang="scss">

.principles {
  background-color: #000100;

  &__title {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-on-primary);
  }

  &__content {
    margin-bottom: var(--padding-large);
  }
}

</style>
  