<template>
  <section id="services" class="services section section--secondary">
    <div class="container services__content">
      <h2 class="services__title section__title">{{ sectionTitle }}</h2>
      <div v-if="services.length" class="services__list">
        <serviceItem v-for="(service, index) in services" :key="index" :service="service" />
      </div>
    </div>
  </section>
</template>
  
  <script>
  import ServiceItem from '@/components/ServiceItem.vue';
  
  export default {
    components: {
      ServiceItem
    },
    data() {
      return {
        sectionTitle: 'Услуги аутсорсинга',
        services: [
          {
            image: 'service_1.png',
            title: 'Разнорабочий',
            description: 'Выполнение подсобных и вспомогательных работ на производстве, мойка полов, окон, тары; очищение территории, дорог; уборка цехов'
          },
          {
            image: 'service_2.png',
            title: 'Услуги грузчиков',
            description: 'Погрузка и разгрузка автотранспорта, контейнеров, вагонов, подъем материалов, перемещение тяжестей'
          },
          {
            image: 'service_3.png',
            title: 'Работник торгового зала',
            description: 'Выкладка товара в торговом зале, расстановка ценников, проверка сроков годности, содержание прилавков в порядке и создание привлекательного внешнего вида витрин'
          },
          {
            image: 'service_4.png',
            title: 'Уборка номерного фонда',
            description: 'Текущая, дополнительная, выездная, генеральная уборка номерного фонда гостиниц'
          },
          {
            image: 'service_5.png',
            title: 'Уборка помещений общего пользования',
            description: 'Cухая и влажная уборка общественных, служебных и складских помещений, ручная уборка и дезинфекция санузлов, в т.ч. стен, сантехники, зеркал, пополнение туалетных принадлежностей̆, удаление пыли и локальных загрязнений'
          },
          {
            image: 'service_6.png',
            title: 'Выполнение хозяйственных работ в помещении и на территории, подсобные работы на кухне',
            description: 'Мытьё  посуды; мытьё кухонного оборудования, мытьё стен, полов, дезинфекцию оборудования'
          }
        ]
      };
    }
  }
  </script>
  
<style scoped lang="scss">

.services {
  text-align: center;
  background-color: #000100;
  
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding-large);
    justify-content: space-between;
    margin-bottom: var(--padding-large);
  }
}

</style>
  