<template>
  <section class="clients section">
    <div class="clients__content container">
      <h2 class="clients__title section__title">{{ title }}</h2>
      <div class="clients__content">
        <div class="clients__list">
          <ClientItem v-for="(client, index) in clients" :key="index" :client="client" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ClientItem from '@/components/ClientItem.vue';
  
  export default {
    components: {
      ClientItem
    },
    data() {
      return {
        title: 'Большой опыт работы',
        clients: [
          {
            image: 'ozon.png',
            name: 'Ozon'
          },
          {
            image: 'gloria_jeans.png',
            name: 'Gloria Jeans'
          },
          {
            image: 'detskiy_mir.png',
            name: 'Детский мир'
          },
          {
            image: 'x5_retail_group.png',
            name: 'X5 Retail Group'
          },
          {
            image: 'sportmaster.png',
            name: 'Спортмастер'
          },
          {
            image: 'swissotel_resort.png',
            name: 'Swissotel Resort'
          },
          {
            image: 'magnit.png',
            name: 'Магнит'
          },
          {
            image: 'faberlic.png',
            name: 'Faberlic'
          },
          {
            image: 'auchan.png',
            name: 'Ашан'
          },
          {
            image: 'lenta.png',
            name: 'Лента'
          },
          {
            image: 'radisson_blu.jpeg',
            name: 'Radisson Blu'
          },
          {
            image: 'park_inn.png',
            name: 'Park Inn'
          }
        ]
      };
    }
  }
</script>
  
<style scoped lang="scss">

.clients {
  text-align: center;

  &__title {
    color: var(--color-supplement-dark);
    margin-bottom: 3rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5rem;
    margin-bottom: var(--padding-large);

    & > * {
      width: clamp(100px, 30vw, 200px);
    }
  }
}

</style>
  